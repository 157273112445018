@use '../_variables'

// common query filter styles
div.query_filter_sections
  margin-block-start: 1rem
  &:first-child
    margin-block-start: variables.$space
  section
    min-height: 2rem
    margin-block-end: 1rem
    &, > div
      display: flex
      justify-content: space-between
      align-items: center
      > span, > p
        white-space: nowrap
    > span i, > div > *, button
      &:not(:last-child)
        margin-inline-end: 1rem
    > div > span:first-child
      margin-inline-end: variables.$space
    > div button:only-child
      margin-inline-start: auto
    > div, p-multiSelect, p-dropdown, p-autocomplete, p-slider, input
      flex: 1
      &:not(:last-child, .p-multiselect-filter, .p-inputtext)
        margin-inline-end: 1rem // add space between items
      .p-multiselect, .p-dropdown
        display: flex
    > gcp-usage-key-value-selector
      &:not(:last-child)
        margin-inline-end: 1rem

    div.period_section
      &, > div, > p-inputswitch
        display: flex
        align-items: center
        height: 100%
        & > .p-inputswitch
          margin-inline-end: variables.$space
      &, > div
        > *:not(:last-child)
          margin-inline-end: variables.$space
      > label
        white-space: nowrap
      > div
        justify-content: space-between

    p-calendar
      &, & span, & input
        width: 100%

  div.btn_group
    justify-content: flex-start
    button
      margin-inline-end: variables.$space
    a
      white-space: nowrap

  section.add_tag_section
    justify-content: flex-start
    > *:not(:last-child)
      margin-inline-end: 1rem

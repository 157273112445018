#report, #invoice
  &.print_mode
    hr
      visibility: hidden
    .card
      background: transparent
      border: 1px solid transparent
      box-shadow: none
    > section
      page-break-inside: avoid
    td
      div > span
        max-width: 30vw
    prime-topbar,
    p-paginator,
    p-toast,
    p-toolbar,
    .layout-menu,
    .layout-mask
      display: none
    .report_metrics
      > div > div
        border: 1px solid transparent
        background: transparent
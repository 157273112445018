@use '../_variables'

p-carousel .p-carousel .p-carousel-dots-container
  margin-block-start: 1rem
  li.p-carousel-dot-item
    margin: 0 variables.$space
    button
      border-radius: 50%
      width: 1rem
      height: 1rem
      > span.p-carousel-dot-icon
        transition: .4s
        width: 100%
        height: 100%
        margin: 0

@use '../_variables'

.p-inputtext
  padding: variables.$space

.p-multiselect .p-placeholder
  padding: variables.$space

.p-multiselect .p-multiselect-label
  padding: variables.$space

.btn_group
  button:not(:last-of-type)
    margin-inline-end: 1rem

.btn_group_reverse
  button:not(:first-of-type)
    margin-inline-end: 1rem

button.p-button:not(.p-button-text-only)
  &:not(.p-button-icon-only)
    > span:first-of-type
      left: .5rem

.p-button, .p-button.p-button-icon-only
  padding: .5rem

.p-button.p-button-icon-only
  border-radius: 100%
  width: 2.75rem
  height: 2.75rem

p-radiobutton
  margin-inline-end: .5rem

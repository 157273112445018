@media print
  html body
    & layout-sidebar
      display: none
    .layout-main
      padding-block-start: 0 !important
      margin-inline-start: 0 !important
      & *
        visibility: visible
      .layout-content
        background: transparent

    #report, #invoice
      .button_group
        display: none
      .card
        border: none
      > section
        page-break-inside: avoid
      .report_metrics
        > div > div
          page-break-inside: avoid
          border: 1px solid transparent
          background: transparent
      .report_charts
        > section.chart_section
          page-break-inside: avoid


    prime-topbar,
    p-paginator,
    p-toast,
    p-toolbar,
    #report-topbar[role='group'],
    .layout-menu,
    .layout-mask
      display: none

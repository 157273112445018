%flexCenter
  display: flex
  justify-content: center
  align-items: center

%uninitialized_animation
  @keyframes uninitialized_animation
    0%
      background: #ddd
    60%
      background: #b3b2b2
    80%
      background: #757575
    90%
      background: #b3b2b2
    100%
      background: #ddd
  animation: uninitialized_animation 1.66s linear infinite

/* You can add global styles to this file, and also import other style files */
$gutter: 1rem //for primeflex grid system

/* Bootstrap */
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css' layer(bootstrap)

/* Angular */
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css'

/* PrimeNG */
@import '../node_modules/primeng/resources/primeng.min.css'
@import '../node_modules/primeng/resources/themes/lara-light-blue/theme.css'
@import '../node_modules/primeicons/primeicons.css'
@import '../node_modules/primeflex/primeflex.scss'

/* Layout */
@import '../sass/primeng/layout/layout'

/* Overrides variables */
@import '../sass/primeng/overrides/_layout_variables'
@import '../sass/primeng/overrides/_theme_variables'

/* Overrides styles */
@import '../sass/primeng/overrides/_layout_styles'
@import '../sass/primeng/overrides/_theme_styles'

/* Quill Text Editor for p-editor */
@import '../node_modules/quill/dist/quill.core.css'
@import '../node_modules/quill/dist/quill.snow.css'

/* Others */
@import '../node_modules/ngx-spinner/animations/ball-spin-clockwise.css'
@import '../node_modules/pivottable/dist/pivot.min.css'

/* Caloudi */
@import '../sass/caloudi_layout/_variables'
@import '../sass/caloudi_layout/_mixins'
@import '../sass/caloudi_layout/_extends'
@import '../sass/caloudi_layout/_printing'
@import '../sass/caloudi_layout/components/_index'

/* NGX Markdown */
@import '../node_modules/prismjs/themes/prism-okaidia.css'
@import '../node_modules/prismjs/plugins/line-highlight/prism-line-highlight.css'
@import '../node_modules/prismjs/plugins/command-line/prism-command-line.css'

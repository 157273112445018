@use '../_variables'

/* New Fix */
p-dialog
  .p-dialog
    max-width: 80vw
    .p-dialog-content
      padding: 1.25rem
    .colorbox_section
      display: flex
      margin-bottom: 1rem
    .colorbox_section caloudi-color-box
      flex: 1
  .chart_area
    &, & .p-tabview-panel
      display: flex
      justify-content: center
      align-items: center
    .p-tabview-panel
      flex: 1
      height: 50vh
      max-width: 100%



// .p-widget-overlay
//   background-color: #58575ca0
//   opacity: unset
// p-dialog .p-dialog
//   max-width: 80vw
//   max-height: 80vh
//   .chart_area
//     &, & .p-tabview-panel
//       display: flex
//       justify-content: center
//       align-items: center
//     .p-tabview-panel
//       flex: 1
//       height: 50vh
//       max-width: 100%
//   .p-dialog-titlebar
//     display: flex
//     box-shadow: 0 -2px 1rem -0.5rem #666
//     .p-dialog-title
//       flex: 1
//       p-header
//         display: flex
//         > span
//           padding-inline-end: .5rem
//           white-space: nowrap
//   .colorbox_section
//     display: flex
//     caloudi-color-box
//       flex: 1

// div.info_section
//   justify-content: flex-start !important
//   flex-flow: wrap
//   p
//     margin-inline-end: 1rem
//     font-weight: 600
//     &:nth-last-child(1)
//       margin-inline-end: 0
//     &:nth-last-of-type(n + 2)::after
//       // content: ','
//       margin-inline-start: -0.25rem
//     span
//       padding: 0 .25rem
//       font-weight: 500

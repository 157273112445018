/* Add your customizations of layout here */

// reset selection colour
::selection
  background-color: var(--selection-bg-color)
  color: #fff

// reset all links default colour
a,
a:link,
a:visited,
a:hover,
a:active
  color: inherit
  cursor: pointer
  text-decoration: none
  user-select: none
  -webkit-user-select: none

.layout-container
  &.layout-horizontal .layout-menu .layout-root-menuitem > ul
    max-height: 50vh

.layout-sidebar .layout-menu li.active-menuitem > a .layout-menuitem-icon
  margin-right: unset

.prime_table>:not(caption)>*>*
  padding: .5rem
.p-datatable
  div.action_group
    display: flex
    justify-content: flex-start
    align-items: center
    gap: .5rem
  td .text-end
    display: block
  // .p-paginator
  //   background: var(--topbar-bg)
  //   &, & .p-paginator-current, & button[type="button"]
  //     color: var(--topbar-item-text-color)
  //   padding: .5rem 1rem
    // & > *, & .p-paginator-pages .p-paginator-page.p-highlight
    //   color: var(--text-color)
  // .p-paginator-top
  //   border-radius: .5rem .5rem 0 0
  // &.p-datatable-striped .p-datatable-table
  //   background-color: unset

.p-divider-solid.p-divider-vertical:before
    border-left-style: solid

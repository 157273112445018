#usage_query
  .p-toolbar
    display: flex
    justify-content: space-between
    align-items: center
    background: var(--menu-bg)
    border: 1px solid var(--surface-border)
    > div
      &:first-child > h6
        font-size: 1.2rem
        font-weight: unset
        margin-bottom: 0
        color: var(--root-menuitem-text-color)
    .p-toolbar-group-end
      flex-flow: row-reverse
      display: flex
      align-items: center
      button:not(:last-child)
        margin-left: .5rem
    .p-toolbar-group-start
      .p-dropdown-panel .p-dropdown-item
        white-space: nowrap
    .p-button.p-button-icon-only
      width: 2rem
      height: 2rem
  & prime-table
    a.instance
      white-space: normal
      text-align: left
      u
        word-break: break-word

// /* Add your customizations of the theme here */

.layout-light
  --bg-color: var(--bg-color-light)

.layout-dark
  --bg-color: var(--bg-color-dark)

.p-toolbar
  background: var(--menu-bg)
  border-radius: 1px
  .p-toolbar-group-end
    flex-flow: row-reverse
    justify-content: flex-start

.p-picklist
  height: 100%
  ul.p-picklist-list
    height: 90%

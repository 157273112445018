/* Add your variable customizations of theme here */
@use 'sass:color'

$bgColor: #fff

:root
  --bg-color-dark: #121212
  --bg-color-light: #fff
  // --bg-color-dark: color.adjust($bgColor, $lightness: -80%)
  // --bg-color-light: color.adjust($bgColor, $lightness: 0%)
  --selection-bg-color: #3030f0
